// tslint:disable

/**
 * Array Grouping
 * Stage 3 Draft / January 4, 2022
 * https://tc39.es/proposal-array-grouping/
 * https://github.com/tc39/proposal-array-grouping
 */

interface Array<T> {
  /**
   * @memberof Array
   *
   * The `groupBy()` method groups the elements of the calling array according to the string
   * values returned by a provided testing function. The returned object has separate properties for each group,
   * containing arrays with the elements in the group. This method should be used when group names can be
   * represented by strings. If you need to group elements using a key that is some arbitrary value, use
   * `Array.prototype.groupByToMap()` instead.
   *
   * Syntax:
   * ```js
   * // Arrow function
   * groupBy((element) => { ... });
   * groupBy((element, index) => { ... });
   * groupBy((element, index, array) => { ... });
   *
   * // Callback function
   * groupBy(callbackFn);
   * groupBy(callbackFn, thisArg);
   *
   * // Inline callback function
   * groupBy(function(element) { ... });
   * groupBy(function(element, index) { ... });
   * groupBy(function(element, index, array){ ... });
   * groupBy(function(element, index, array) { ... }, thisArg);
   * ```
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/groupBy
   */
  groupBy<K extends keyof any>(callback: (value: T, index: number, array: T[]) => K, thisArg?: any): { [P in K]: T[] };

  /**
   * @memberof Array
   *
   * The `groupByToMap()` method groups the elements of the calling array using the values returned by a
   * provided testing function. The final returned Map uses the unique values from the test function as keys,
   * which can be used to get the array of elements in each group. The method is primarily useful when
   * grouping elements that are associated with an object, and in particular when that object might change
   * over time. If the object is invariant, you might instead represent it using a string, and group elements
   * with `Array.prototype.groupBy()`.
   *
   * Syntax:
   * ```js
   * // Arrow function
   * groupByToMap((element) => { ... });
   * groupByToMap((element, index) => { ... });
   * groupByToMap((element, index, array) => { ... });
   *
   * // Callback function
   * groupByToMap(callbackFn);
   * groupByToMap(callbackFn, thisArg);
   *
   * // Inline callback function
   * groupByToMap(function(element) { ... });
   * groupByToMap(function(element, index) { ... });
   * groupByToMap(function(element, index, array){ ... });
   * groupByToMap(function(element, index, array) { ... }, thisArg);
   * ```
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/groupByToMap
   */
  groupByToMap<K>(callback: (value: T, index: number, array: T[]) => K, thisArg?: any): Map<K, T[]>;
}

Array.prototype.groupBy ??= function <T, K extends keyof any>(
  callback: (value: T, index: number, array: Array<T>) => K,
  thisArg?: any
): { [P in K]: T[] } {
  const obj: { [P in K]: T[] } = {} as any;
  this.forEach((value, idx, self) => {
    // Always using apply will break #private values
    const ret = thisArg ? callback.call(thisArg, value, idx, self) : callback(value, idx, self);

    (obj[ret] ??= []).push(value);
  });

  return obj;
};

Array.prototype.groupByToMap ??= function <T, K>(
  callback: (value: T, index: number, array: Array<T>) => K,
  thisArg?: any
): Map<K, Array<T>> {
  const map = new Map<K, Array<T>>();
  this.forEach((value, idx, self) => {
    // Always using apply will break #private values
    const ret = thisArg ? callback.call(thisArg, value, idx, self) : callback(value, idx, self);

    // Upsert
    const group = map.get(ret) || [];
    if (group.push(value) === 1) {
      map.set(ret, group);
    }
  });

  return map;
};
